import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "check-detail" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { class: "dialogVisible-info" }
const _hoisted_4 = { class: "dialogVisible-content" }
const _hoisted_5 = { class: "content-table" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FargoTable = _resolveComponent("FargoTable")!
  const _component_QuestionFilled = _resolveComponent("QuestionFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_FargoPagination = _resolveComponent("FargoPagination")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.activeNameValue === 'HK' || _ctx.activeNameValue === 'AG')
        ? (_openBlock(), _createBlock(_component_FargoTable, {
            key: 0,
            "table-loading": _ctx.tableLoading,
            list: _ctx.tableData,
            "onUpdate:list": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tableData) = $event)),
            "t-header-list": _ctx.tHeaderListHk,
            stripe: false,
            onChange: _ctx.change,
            height: _ctx.height,
            fixed: ""
          }, null, 8, ["table-loading", "list", "t-header-list", "onChange", "height"]))
        : _createCommentVNode("", true),
      (_ctx.activeNameValue === 'US')
        ? (_openBlock(), _createBlock(_component_FargoTable, {
            key: 1,
            "table-loading": _ctx.tableLoading,
            list: _ctx.tableData,
            "onUpdate:list": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tableData) = $event)),
            "t-header-list": _ctx.tHeaderList,
            stripe: false,
            onChange: _ctx.change,
            height: _ctx.height,
            fixed: ""
          }, null, 8, ["table-loading", "list", "t-header-list", "onChange", "height"]))
        : _createCommentVNode("", true),
      (_ctx.activeNameValue === 'TW')
        ? (_openBlock(), _createBlock(_component_FargoTable, {
            key: 2,
            "table-loading": _ctx.tableLoading,
            list: _ctx.tableData,
            "onUpdate:list": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tableData) = $event)),
            "t-header-list": _ctx.tHeaderListTW,
            stripe: false,
            onChange: _ctx.change,
            height: _ctx.height,
            fixed: ""
          }, {
            orderNum: _withCtx((scope) => [
              _createVNode(_component_el_space, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(scope.row.orderNum), 1),
                  (scope.row.showTips === 1)
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 0,
                        content: "当前为非交易时段或休市，订单会在早市竞价时段开始（台湾时间08:30）后排队提交，排队时请耐心等待",
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_QuestionFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["table-loading", "list", "t-header-list", "onChange", "height"]))
        : _createCommentVNode("", true),
      false
        ? (_openBlock(), _createBlock(_component_FargoPagination, {
            key: 3,
            total: _ctx.total,
            page: _ctx.page,
            "onUpdate:page": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.page) = $event)),
            limit: _ctx.limit,
            "onUpdate:limit": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.limit) = $event)),
            onPagination: _ctx.getData
          }, null, 8, ["total", "page", "limit", "onPagination"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisibleFlag,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dialogVisibleFlag) = $event)),
      title: "",
      width: "500px",
      "destroy-on-close": "",
      center: "",
      "show-close": false,
      class: "ce-dialog"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_el_button, {
            class: "content-close",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.dialogVisibleFlag = false))
          }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [
              _createTextVNode("取消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            loading: _ctx.btnLoading,
            class: "content-confirm",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.confirm(_ctx.dataForm))),
            type: "primary"
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode("确定")
            ])),
            _: 1
          }, 8, ["loading"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[19] || (_cache[19] = _createElementVNode("span", { class: "content-title" }, "撤单确认", -1)),
            _cache[20] || (_cache[20] = _createElementVNode("span", { class: "line" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _cache[8] || (_cache[8] = _createElementVNode("span", null, "账号", -1)),
                _createElementVNode("span", null, "现金账户(" + _toDisplayString(_ctx.dataForm.fundAccount) + ")", 1)
              ]),
              _createElementVNode("div", null, [
                _cache[9] || (_cache[9] = _createElementVNode("span", null, "订单类型", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.checkType(_ctx.dataForm.entrustProp)), 1)
              ]),
              _createElementVNode("div", null, [
                _cache[10] || (_cache[10] = _createElementVNode("span", null, "方向", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.dataForm.entrustBs === "1" ? "买入" : "卖出"), 1)
              ]),
              _createElementVNode("div", null, [
                _cache[11] || (_cache[11] = _createElementVNode("span", null, "名称", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.dataForm.stockName), 1)
              ]),
              _createElementVNode("div", null, [
                _cache[12] || (_cache[12] = _createElementVNode("span", null, "代码", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.dataForm.stockCode) + "." + _toDisplayString(_ctx.dataForm.exchangeType), 1)
              ]),
              (_ctx.dataForm.exchangeType === 'TW')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[13] || (_cache[13] = [
                    _createElementVNode("span", null, "期限", -1),
                    _createElementVNode("span", null, "当日有效", -1)
                  ])))
                : _createCommentVNode("", true),
              (_ctx.dataForm.exchangeType === 'TW' && _ctx.isAfterForDate())
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[14] || (_cache[14] = [
                    _createElementVNode("span", { class: "red-text" }, "下一个交易日收盘前", -1),
                    _createElementVNode("span", null, null, -1)
                  ])))
                : _createCommentVNode("", true),
              (_ctx.dataForm.exchangeType === 'US')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _cache[15] || (_cache[15] = _createElementVNode("span", null, "盘前盘后", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.dataForm.extendedTrading), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _cache[16] || (_cache[16] = _createElementVNode("span", null, "价格", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.dataForm.entrustPrice), 1)
              ]),
              _createElementVNode("div", null, [
                _cache[17] || (_cache[17] = _createElementVNode("span", null, "委托数量", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.numFormat(_ctx.dataForm.entrustAmount, 0)) + "股", 1)
              ]),
              _createElementVNode("div", null, [
                _cache[18] || (_cache[18] = _createElementVNode("span", null, "成交数量", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.numFormat(_ctx.dataForm.businessAmount, 0)) + "股", 1)
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}