
/* eslint-disable */
export const tHeaderList = [
  // style: '{ color: entrustBs === '1' ? '#f6af76' : '#000' }'
  // {
  //   label: '操作',
  //   prop: 'entrustStatus',
  //   type: 'orderAction',
  //   btnType: 'status',
  //   width: '120'
  // },
  {
    label: "操作",
    fixed: "left",
    type: "setting",
    width: "120",
    crossFlag: true,
    setting: [
      {
        name: "重新下单",
        btnType: "reorder",
        color: "text",
        colorStyle: "",
        typeReal: "entrustStatusCx", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      // 灰色改单
      {
        name: "改单",
        btnType: "status1",
        color: "text",
        colorStyle: "#7E7E7E",
        typeReal: "entrustStatusGDHs", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      {
        name: "改单",
        btnType: "toChange",
        color: "text",
        colorStyle: "#FFB400",
        typeReal: "entrustStatusGD", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      {
        name: "撤单",
        btnType: "status",
        color: "text",
        colorStyle: "#386AFF",
        typeReal: "entrustStatusCD", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      {
        name: "entrustStatusMC",
        btnType: "edit",
        youName: "entrustStatusMC",
        color: "text",
        colorStyle: "#7E7E7E",
        typeReal: "entrustStatusQT", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
    ],
  },
  { label: "订单号", prop: "orderNum", type: "", width: "" },
  {
    label: "方向",
    prop: "entrustBs",
    type: "renderHTML",
    width: "",
    render: (data: any) => {
      if (data) {
        return data === "1"
          ? `<p style="color:#ffb400;font-weight:500">买入</p>`
          : `<p style="color:#386AFF;font-weight:500">卖出</p>`;
      }
    },
  },
  // { label: '客户账号', prop: 'fundAccount', type: '', width: '' },
  // { label: '客户', prop: 'nameCn', type: '', width: '' },
  {
    label: "股票名称/代码",
    prop: "enName",
    type: "twoLinesIconS",
    width: "220px",
    propList: [
      { prop: "stockName", icon: "exchangeType", class: "lines-stock-name" },
      { prop: "stockCode", class: "lines-stock-code" },
      { prop: "exchangeType", class: "lines-stock-type" },
    ],
  },
  {
    label: "委托价格/数量",
    prop: "entrustPrice",
    type: "twoLinesTab",
    width: "120",
    propList: ["entrustPriceN", "entrustAmountN"],
  },
  {
    label: "成交均价/数量",
    prop: "entrustPrice",
    type: "twoLinesTab",
    width: "120",
    propList: ["dealPrice", "quantityN"],
  },
  // { label: '委托价格', prop: 'entrustPrice', type: '', width: '' },
  // {
  //   label: '委托数量',
  //   prop: 'entrustAmountN',
  //   type: '',
  //   width: ''
  // },
  // {
  //   label: '成交均价',
  //   prop: 'dealPrice',
  //   type: '',
  //   width: ''
  // },
  // {
  //   label: '成交数量',
  //   prop: 'quantityN',
  //   type: '',
  //   width: ''
  // },
  {
    label: "盘前盘后",
    prop: "extendedTrading",
    type: "",
    hide: "hide",
    class: "hide-none",
    width: "120",
  },
  {
    label: "交易状态",
    prop: "entrustStatus",
    type: "status",
    width: "",
  },
  {
    label: "订单类型",
    prop: "entrustProp",
    type: "entrustProp",
    width: "120",
  },
  {
    label: "下单时间",
    prop: "orderTimer",
    type: "",
    width: "180",
  },
];
export const tHeaderListHk = [
  // style: '{ color: entrustBs === '1' ? '#f6af76' : '#000' }'
  // {
  //   label: '操作',
  //   prop: 'entrustStatus',
  //   type: 'orderAction',
  //   btnType: 'status',
  //   width: '120'
  // },
  {
    label: "操作",
    fixed: "left",
    type: "setting",
    width: "120",
    crossFlag: true,
    setting: [
      {
        name: "重新下单",
        btnType: "reorder",
        color: "text",
        colorStyle: "",
        typeReal: "entrustStatusCx", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      // 灰色改单
      {
        name: "改单",
        btnType: "status1",
        color: "text",
        colorStyle: "#7E7E7E",
        typeReal: "entrustStatusGDHs", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      {
        name: "改单",
        btnType: "toChange",
        color: "text",
        colorStyle: "#FFB400",
        typeReal: "entrustStatusGD", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      {
        name: "撤单",
        btnType: "status",
        color: "text",
        colorStyle: "#386AFF",
        typeReal: "entrustStatusCD", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      {
        name: "entrustStatusMC",
        btnType: "edit",
        youName: "entrustStatusMC",
        color: "text",
        colorStyle: "#7E7E7E",
        typeReal: "entrustStatusQT", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
    ],
  },
  { label: "订单号", prop: "orderNum", type: "", width: "" },
  {
    label: "方向",
    prop: "entrustBs",
    type: "renderHTML",
    width: "",
    render: (data: any) => {
      if (data) {
        return data === "1"
          ? `<p style="color:#ffb400;font-weight:500">买入</p>`
          : `<p style="color:#386AFF;font-weight:500">卖出</p>`;
      }
    },
  },
  // { label: '客户账号', prop: 'fundAccount', type: '', width: '' },
  // { label: '客户', prop: 'nameCn', type: '', width: '' },
  {
    label: "股票名称/代码",
    prop: "enName",
    type: "twoLinesIconS",
    width: "220px",
    propList: [
      { prop: "stockName", icon: "exchangeType", class: "lines-stock-name" },
      { prop: "stockCode", class: "lines-stock-code" },
      { prop: "exchangeType", class: "lines-stock-type" },
    ],
  },
  {
    label: "委托价格/数量",
    prop: "entrustPrice",
    type: "twoLinesTab",
    width: "120",
    propList: ["entrustPriceN", "entrustAmountN"],
  },
  {
    label: "成交均价/数量",
    prop: "entrustPrice",
    type: "twoLinesTab",
    width: "120",
    propList: ["dealPrice", "quantityN"],
  },
  // { label: '委托价格', prop: 'entrustPrice', type: '', width: '' },
  // {
  //   label: '委托数量',
  //   prop: 'entrustAmountN',
  //   type: '',
  //   width: ''
  // },
  // {
  //   label: '成交均价',
  //   prop: 'dealPrice',
  //   type: '',
  //   width: ''
  // },
  // {
  //   label: '成交数量',
  //   prop: 'quantityN',
  //   type: '',
  //   width: ''
  // },
  // {
  //   label: '盘前盘后',
  //   prop: 'extendedTrading',
  //   type: '',
  //   hide: 'hide',
  //   width: '120'
  // },
  {
    label: "交易状态",
    prop: "entrustStatus",
    type: "status",
    width: "",
  },
  {
    label: "订单类型",
    prop: "entrustProp",
    type: "entrustProp",
    width: "120",
  },
  {
    label: "下单时间",
    prop: "orderTimer",
    type: "",
    width: "180",
  },
];

export const tHeaderListTW = [
  // style: '{ color: entrustBs === '1' ? '#f6af76' : '#000' }'
  // {
  //   label: '操作',
  //   prop: 'entrustStatus',
  //   type: 'orderAction',
  //   btnType: 'status',
  //   width: '120'
  // },
  {
    label: "操作",
    fixed: "left",
    type: "setting",
    width: "120",
    crossFlag: true,
    setting: [
      {
        name: "重新下单",
        btnType: "reorder",
        color: "text",
        colorStyle: "",
        typeReal: "entrustStatusCx", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      // 灰色改单
      {
        name: "改单",
        btnType: "status1",
        color: "text",
        colorStyle: "#7E7E7E",
        typeReal: "entrustStatusGDHs", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      {
        name: "改单",
        btnType: "toChange",
        color: "text",
        colorStyle: "#FFB400",
        typeReal: "entrustStatusGD", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      {
        name: "撤单",
        btnType: "status",
        color: "text",
        colorStyle: "#386AFF",
        typeReal: "entrustStatusCD", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
      {
        name: "entrustStatusMC",
        btnType: "edit",
        youName: "entrustStatusMC",
        color: "text",
        colorStyle: "#7E7E7E",
        typeReal: "entrustStatusQT", // 显示控制的字段
        showValue: 1, // 具体字段显示的值
        permission: [],
      },
    ],
  },
  {
    label: "订单号",
    prop: "orderNum",
    type: "slot",
    width: "160px",
  },
  {
    label: "方向",
    prop: "entrustBs",
    type: "renderHTML",
    width: "",
    render: (data: any) => {
      if (data) {
        return data === "1"
          ? `<p style="color:#ffb400;font-weight:500">买入</p>`
          : `<p style="color:#386AFF;font-weight:500">卖出</p>`;
      }
    },
  },
  // { label: '客户账号', prop: 'fundAccount', type: '', width: '' },
  // { label: '客户', prop: 'nameCn', type: '', width: '' },
  {
    label: "股票名称/代码",
    prop: "enName",
    type: "twoLinesIconS",
    width: "220px",
    propList: [
      { prop: "stockName", icon: "exchangeType", class: "lines-stock-name" },
      { prop: "stockCode", class: "lines-stock-code" },
      { prop: "exchangeType", class: "lines-stock-type" },
    ],
  },
  {
    label: "委托价格/数量",
    prop: "entrustPrice",
    type: "twoLinesTab",
    width: "120",
    propList: ["entrustPriceN", "entrustAmountN"],
  },
  {
    label: "成交均价/数量",
    prop: "entrustPrice",
    type: "twoLinesTab",
    width: "120",
    propList: ["dealPrice", "quantityN"],
  },
  // { label: '委托价格', prop: 'entrustPrice', type: '', width: '' },
  // {
  //   label: '委托数量',
  //   prop: 'entrustAmountN',
  //   type: '',
  //   width: ''
  // },
  // {
  //   label: '成交均价',
  //   prop: 'dealPrice',
  //   type: '',
  //   width: ''
  // },
  // {
  //   label: '成交数量',
  //   prop: 'quantityN',
  //   type: '',
  //   width: ''
  // },
  // {
  //   label: '盘前盘后',
  //   prop: 'extendedTrading',
  //   type: '',
  //   hide: 'hide',
  //   class: 'hide-none',
  //   width: '120'
  // },
  {
    label: "交易状态",
    prop: "entrustStatus",
    type: "status",
    width: "",
  },
  {
    label: "订单类型",
    prop: "entrustProp",
    type: "entrustProp",
    width: "120",
  },
  {
    label: "下单时间",
    prop: "orderTimer",
    type: "",
    width: "180",
  },
];
