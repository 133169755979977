
import { useMutation, useQuery } from '@vue/apollo-composable'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { QuestionFilled } from '@element-plus/icons-vue'
import { computed, defineComponent, reactive, toRefs, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { tHeaderList, tHeaderListHk, tHeaderListTW } from './data'
import * as gql from '@/graphql'
import storage, { StorageType } from '@/utils/storage'
import { numFormat } from '@/utils/num_format'
import { useStore } from '@/store'
import {
  STATES,
  ENTRUSTPROPMAP,
  ORDER_CANCEL,
  LPOASTATE
} from '@/constant/index'
import { state } from '@/store/modules/app/state'
export default defineComponent({
  components: {
    QuestionFilled
  },
  props: {
    activeNameValue: { type: String, default: 'Hk' }
  },
  emits: [
    'changOrderForm',
    'againOrderForm',
    'cancelOrderSuccess',
    'update:tableDataL'
  ],
  name: 'checkDetail',
  setup(props, { emit }) {
    const clientId = storage.rcGetItem(StorageType.local, 'userInfo')
    const store: any = useStore()
    const route = useRoute()
    const router = useRouter()
    const query = computed(() => route.query)
    const datas = reactive({
      tableData: [],
      tHeaderList: tHeaderList,
      tHeaderListHk: tHeaderListHk,
      tHeaderListTW: tHeaderListTW,
      dialogVisibleFlag: false, // 查看明细
      dataForm: {},
      height: window.innerHeight - 400 < 467 ? 467 : window.innerHeight - 400,
      page: 1,
      limit: 4000,
      total: 0,
      btnLoading: false,
      tableLoading: false,
      fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
    })
    watch(
      () => props.activeNameValue,
      (newValue: any) => {
        console.log(newValue, '????')
        const params = {
          filter: {
            fundAccount: datas.fundAccount,
            startDate: Number(dayjs(new Date()).format('YYYYMMDD')),
            endDate: Number(dayjs(new Date()).format('YYYYMMDD')),
            pageNo: datas.page,
            pageSize: 50000,
            exchangeType: newValue === 'AG' ? 'A' : newValue
          }
        }
        datas.tableLoading = true
        // eslint-disable-next-line
        refetch(params);
      },
      { deep: true }
    )
    // 获取个人信息列表 HisOriginal
    const hisOriginalParams = ref({
      filter: {
        fundAccount: datas.fundAccount,
        startDate: Number(dayjs(new Date()).format('YYYYMMDD')),
        endDate: Number(dayjs(new Date()).format('YYYYMMDD')),
        pageNo: 1,
        pageSize: 4000,
        exchangeType:
          props.activeNameValue === 'AG' ? 'A' : props.activeNameValue
      }
    })
    const { refetch, onResult } = useQuery(gql.HisOriginal, hisOriginalParams, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    })
    datas.tableLoading = true
    onResult((res) => {
      if (res.data) {
        const arrStatus = ['0', '1', '2', '7']
        const arrStatusT = [
          '0',
          '1',
          '2',
          '7',
          '9',
          'B',
          'D',
          'H',
          'J',
          'N',
          'O',
          'P',
          'W'
        ]
        console.log(res.data, '请求了数据')
        const arr = res.data.hisOriginal?.data?.rows || []
        // arr = arr.filter((v: any) => v.exchangeType === props.activeNameValue)
        // if (props.activeNameValue === 'HK') {
        //   tHeaderList = tHeaderList
        // }
        arr.forEach((v: any) => {
          v.dealPrice = v.orderDetail.filter(
            (v: any) => v.title === '成交均价'
          )[0].value
          v.quantity = v.orderDetail.filter(
            (v: any) => v.title === '成交数量'
          )[0].value
          v.extendedTrading = v.orderDetail.filter(
            (v: any) => v.title === '盘前盘后'
          )[0]?.value
          v.orderTimer = v.orderDetail.filter(
            (v: any) => v.title === '下单时间'
          )[0]?.value
          v.quantityN =
            v.quantity !== '--' ? numFormat(v.quantity, 0) : v.quantity
          v.orderNum = v.orderDetail.filter(
            (v: any) => v.title === '订单号'
          )[0]?.value
          v.cancelOrderText = v.orderDetail.filter(
            (v: any) => v.title === '作废原因'
          )[0]?.value
          v.entrustPriceN =
            v.entrustProp === 'MO' || v.entrustProp === 'AMO'
              ? '市价'
              : v.entrustPrice
          v.entrustAmountN = numFormat(v.entrustAmount, 0)
          // 处理重新下单 => 撤单 => 改单 逻辑
          // 重新下单
          v.entrustStatusCx = v.entrustStatus === '9' ? 1 : 0
          // 撤单
          v.entrustStatusCD = ORDER_CANCEL.includes(v.entrustStatus) ? 1 : 0
          // 改单
          v.entrustStatusGD =
            v.exchangeType === 'SH' || v.exchangeType === 'SZ'
              ? 0
              : arrStatus.includes(v.entrustStatus) &&
                v.entrustProp !== 'MO' &&
                v.entrustProp !== 'AMO'
                ? 1
                : 0
          // 其他显示
          v.entrustStatusQT = arrStatusT.includes(v.entrustStatus) ? 0 : 1
          // 其他名称
          v.entrustStatusMC = STATES[v.entrustStatus]
          // 改单灰色不可点击状态 => 改单
          v.entrustStatusGDHs =
            ['B', 'D', 'H', 'J', 'N', 'O', 'P', 'W'].includes(
              v.entrustStatus
            ) &&
              (v.entrustProp !== 'MO' || v.entrustProp !== 'AMO')
              ? 1
              : 0
        })
        datas.tableData = arr
        emit('update:tableDataL', datas.tableData?.length)
        datas.total = res.data.hisOriginal?.data?.totalCount
        datas.tableLoading = false
      }
    })
    const getData = () => {
      datas.tableLoading = true
      const params = {
        filter: {
          fundAccount: datas.fundAccount,
          startDate: Number(dayjs(new Date()).format('YYYYMMDD')),
          endDate: Number(dayjs(new Date()).format('YYYYMMDD')),
          pageNo: datas.page,
          pageSize: datas.limit,
          exchangeType:
            props.activeNameValue === 'AG' ? 'A' : props.activeNameValue
        }
      }
      refetch(params)
    }
    // 刷新数据的方法
    const refetchData = () => {
      const params = {
        filter: {
          fundAccount: datas.fundAccount,
          startDate: Number(dayjs(new Date()).format('YYYYMMDD')),
          endDate: Number(dayjs(new Date()).format('YYYYMMDD')),
          pageNo: datas.page,
          pageSize: Math.floor(Math.random() * 100000),
          exchangeType:
            props.activeNameValue === 'AG' ? 'A' : props.activeNameValue
        }
      }
      datas.tableLoading = true
      // eslint-disable-next-line
      refetch(params);
    }
    // 撤单
    const { mutate: cancelOrder } = useMutation(gql.revokeOrder)
    const change = (data: any) => {
      console.log('data22', data)
      switch (data.btnType) {
        case 'status':
          let unlock
          const currentClient = storage.rcGetItem(StorageType.local, 'currentClient')
          const immigrantMainAccout = storage.rcGetItem(StorageType.local, 'immigrantMainAccout')
          // 目前只有投资移民主子账号公用主交易密码
          if (immigrantMainAccout && [9, 10].includes(currentClient.clientType) && currentClient.fundAccountType === 3) {
            unlock = storage.rcGetItem(StorageType.session, `unlock-immigrant-${immigrantMainAccout}`)
          } else {
            unlock = storage.rcGetItem(StorageType.session, `unlock-${currentClient.clientId}`)
          }

          if (!unlock) {
            emit('cancelOrderSuccess', '-1')
            return
          }
          datas.dialogVisibleFlag = true
          console.log(data.data)
          datas.dataForm = data.data
          break
        case 'status1':
          ElMessage({
            message: '该订单不允许改单',
            type: 'warning',
            customClass: 'custom-warning'
          })
          break
        case 'toChange':
          console.log(data.data)
          const changOrderForm: any = {
            entrustAmount: data.data.entrustAmount,
            entrustPrice: data.data.entrustPrice,
            fundAccount: data.data.fundAccount,
            entrustBs: data.data.entrustBs,
            entrustProp: data.data.entrustProp,
            exchangeType: data.data.exchangeType,
            extendedTrading: data.data.extendedTrading,
            stockCode: data.data.stockCode,
            orderTxnReference: data.data.orderTxnReference,
            stockNameGb: data.data.stockName
          }
          emit('changOrderForm', changOrderForm)
          break
        case 'reorder':
          const bidAskList: any = {
            ...data.data,
            entrustAmount: data.data.entrustAmount,
            fundAccount: data.data.fundAccount,
            entrustProp: data.data.entrustProp,
            exchangeType: data.data.exchangeType,
            stockCode: data.data.stockCode,
            extendedTrading: data.data.extendedTrading,
            stockNameGb: data.data.stockName
          }
          console.log(bidAskList, '重新下单')
          emit('againOrderForm', bidAskList)
          // router.push({
          //   name: 'BulkTrading',
          //   params: {
          //     bidAskList: JSON.stringify(bidAskList)
          //   }
          // })
          break
        default:
          break
      }
    }
    const ress = computed(() => store.state.quot.orderList)
    // const result = store.dispatch('getList', hisOriginalParams.value)
    watch(
      () => ress.value,
      (newValue: any) => {
        if (newValue === 'LS') {
          return
        }
        const params = {
          filter: {
            fundAccount: datas.fundAccount,
            startDate: Number(dayjs(new Date()).format('YYYYMMDD')),
            endDate: Number(dayjs(new Date()).format('YYYYMMDD')),
            pageNo: datas.page,
            pageSize: Math.floor(Math.random() * 100000),
            exchangeType:
              props.activeNameValue === 'AG' ? 'A' : props.activeNameValue
          }
        }
        refetch(params)
      },
      { deep: true }
    )
    const checkType = (val: any) => {
      const list = [
        {
          label: '限价单 (增强) ',
          value: 'ELO',
          valueOf: 'ELO1'
        },
        {
          label: '市价单',
          value: 'MO',
          valueOf: 'MO1'
        },
        {
          label: '竞价限价单',
          value: 'ALO',
          valueOf: 'ALO1'
        },
        {
          label: '竞价市价单',
          value: 'AMO',
          valueOf: 'AMO1'
        },
        {
          label: '限价单',
          value: 'LO',
          valueOf: 'LO2'
        }
      ]
      return list.filter((v: any) => v.value === val)[0].label
    }
    const confirm = (dataForm: any) => {
      datas.btnLoading = true
      console.log(dataForm, 'dataForm')
      const dataObj = {
        orderTxnReference: dataForm.orderTxnReference
      }
      console.log(dataObj)
      cancelOrder({ params: dataObj }).then((result: any) => {
        console.log(result)
        const { revokeOrder } = result.data
        if (revokeOrder.ok) {
          ElMessage({
            message: '撤单已提交',
            type: 'success',
            customClass: 'custom-success'
          })
          datas.page = 1
          const params = {
            filter: {
              fundAccount: datas.fundAccount,
              startDate: Number(dayjs(new Date()).format('YYYYMMDD')),
              endDate: Number(dayjs(new Date()).format('YYYYMMDD')),
              pageNo: datas.page,
              pageSize: 50000,
              exchangeType:
                props.activeNameValue === 'AG' ? 'A' : props.activeNameValue
            }
          }
          datas.tableLoading = true
          refetch(params)
          datas.dialogVisibleFlag = false
          datas.btnLoading = false
          emit('cancelOrderSuccess', '1')
          return
        }
        datas.btnLoading = false
        ElMessage({
          message: revokeOrder.reason.desc || '撤单失败',
          type: 'error',
          customClass: 'custom-error'
        })
        if (revokeOrder.reason.err === 20021) {
          emit('cancelOrderSuccess', '0')
        }
      })
    }

    const isAfterForDate = (h = 17, date?: any): boolean => {
      let time = date
      if (!time) {
        time = dayjs()
      }
      const hour = dayjs(time).hour()
      return hour >= h
    }
    return {
      ...toRefs(datas),
      change,
      confirm,
      query,
      checkType,
      getData,
      refetchData,
      numFormat,
      isAfterForDate
    }
  }
})
